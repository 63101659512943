<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import SettingsService from '@/services/settings';

export default {
  page: {
    title: "Configurações",
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Configurações",
      settings: {},
    }
  },
  mounted() {
    this.load(true);
  },
  methods: {
    load(showLoading) {
      let loader;
      if (showLoading) {
        loader = this.$loading.show();
      }

      SettingsService.getSettings().then(res => {
        this.settings = res.data;
        
        if (showLoading) {
          loader.hide();
        }
      }).catch(() => {
        if (showLoading) {
          loader.hide();
        }

        this.$toast.error('Ocorreu um erro ao executar essa ação. Tente novamente.');
      })
    },
    save() {
      let loader = this.$loading.show();

      SettingsService.updateSettings(this.settings).then(() => {
        loader.hide();
        this.load(false);
      }).catch(() => {
        loader.hide();
        this.$toast.error('Ocorreu um erro ao executar essa ação. Tente novamente.');
      })
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">FINANCEIRO/SERVIÇOS</h4>
            <p class="card-title-desc">Definições para a contratação dos serviços</p>

            <div class="row mb-2">
              <div class="col-sm-2">
                <div class="form-group">
                  <label for="productname">Taxa de Serviço (Oficina)</label>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span id="validationTooltipUsernamePrepend" class="input-group-text">%</span>
                    </div>
                    <input id="productname" name="productname" type="tel" class="form-control" v-model="settings.serviceTaxHirer" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-sm-2">
                <div class="form-group">
                  <label for="productname">Taxa de Serviço (Prestador)</label>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span id="validationTooltipUsernamePrepend" class="input-group-text">%</span>
                    </div>
                    <input id="productname" name="productname" type="tel" class="form-control" v-model="settings.serviceTaxProvider" />
                  </div>
                </div>
              </div>
            </div>

            <button type="submit" class="btn btn-primary mr-1" @click="save">Salvar Configuração</button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>